import { useEffect, useState } from "react";
import { sendMail } from "../ApiServices/HomePageController";
import { toast } from "react-toastify";
import { useSnackbar } from "notistack";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function SignUp() {
  return (
    <>
      <Header></Header>
      <div className="signup">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdFXY45MgZMfeTEX5xEsQiItnx4a6Sgk2WqG_ay6IA2g0399A/viewform?embedded=true"
          width="100%"
          height="600px"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
      <Footer></Footer>
    </>
  );
}
