import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Privacy() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="Conditions-hed  mb-3">Privacy Policy –</div>
        <p className="Privacy-hed">
          At COPT Education Private Limited, our utmost priority is to safeguard
          the privacy and security of our users' personal information. This
          Privacy Policy has been formulated to elucidate how we collect,
          utilize, and protect the information you provide when using our EdTech
          platform. By accessing or using our platform, you explicitly agree to
          abide by the terms and conditions outlined in this Privacy Policy.
        </p>
        <p className="Privacy-hed">
          Information Collection
          <br />
          1.1 Personal Information: When you register an account or use our
          platform, we may collect personal information, such as your name,
          email address, username, password, and any other details you
          voluntarily provide.
        </p>
        <p className="Privacy-hed">
          1.2 Usage Information: We gather information about your platform
          usage, including your interactions, activities, and preferences. This
          may comprise data about the courses you undertake, materials accessed,
          assessments completed, and any communication exchanged through our
          platform.
        </p>
        <p className="Privacy-hed">
          1.3 Device and Log Information: We automatically collect certain
          details about your device, such as IP address, browser type, operating
          system, device identifiers, and referring URLs. This information is
          logged for analytical purposes, enabling us to enhance our platform's
          performance and user experience.
        </p>
        <p className="Privacy-hed">
          Information Utilization
          <br />
          2.1 Provision and Improvement of Services: The information we collect
          is utilized to deliver, personalize, and enhance our EdTech platform,
          including the development of new features and services. This may
          involve analyzing user behavior, conducting research, and refining
          user experiences.
        </p>
        <p className="Privacy-hed">
          2.2 Communication: We may employ your contact information to send
          administrative and promotional emails, updates, newsletters, and other
          platform-related communications. You can choose to unsubscribe from
          receiving promotional emails by following the instructions provided in
          the email.
        </p>
        <p className="Privacy-hed">
          2.3 Aggregated Data: We may aggregate and anonymize the collected
          information for statistical or analytical purposes. Aggregated data
          does not personally identify you and may be used for research,
          marketing, or other legitimate purposes.
        </p>
        <p className="Privacy-hed">
          2.4 Compliance and Legal Obligations: Your information may be utilized
          to comply with applicable laws, regulations, legal processes, or
          enforceable governmental requests. Additionally, we may employ it to
          detect and prevent fraud, protect our rights, privacy, safety, or
          property, or those of our users or the public.
        </p>
        <p className="Privacy-hed">
          Information Sharing
          <br />
          3.1 Service Providers: We may share your information with trusted
          third-party service providers who assist us in delivering our
          services, maintaining our platform, and performing related activities.
          These providers are obligated to maintain the confidentiality of your
          information and use it solely for the purposes specified by us.
        </p>
        <p className="Privacy-hed">
          3.2 Business Transfers: In the event of a merger, acquisition, or sale
          of all or a portion of our assets, your information may be transferred
          to the acquiring entity as part of the transaction. If such a transfer
          occurs, we will notify you through prominent notice on our website or
          via email if your personal information becomes subject to a different
          privacy policy.
        </p>
        <p className="Privacy-hed">
          3.3 Legal Requirements: We may disclose your information if required
          to do so by law or in good faith belief that such action is necessary
          to comply with legal obligations, protect our rights, investigate
          fraud, or respond to a government request.
        </p>
        <p className="Privacy-hed">
          Data Security
          <br />
          4.1 We employ reasonable security measures to protect your personal
          information from unauthorized access, alteration, disclosure, or
          destruction. However, it is important to note that no data
          transmission over the internet or electronic storage method is
          entirely secure. While we strive to protect your personal information,
          we cannot guarantee its absolute security.
        </p>
        <p className="Privacy-hed">
          Children's Privacy
          <btr />
          5.1 Our EdTech platform is intended for individuals who are 13 years
          of age or older. We do not knowingly collect personal information from
          children under the age of 13. If we become aware that we have
          inadvertently collected personal information from a child under 13, we
          will take steps to delete it as soon as possible. If you believe we
          may have collected information from a child under 13, please contact
          us immediately.
        </p>
        <p className="Privacy-hed">
          Display of Rank Holders in Competitive Exams
          <br />
          6.1 Purpose: This policy outlines the guidelines and procedures for
          showcasing rank holders on our EdTech platform when the results of
          competitive exams are declared. We consider accessing any resource
          from our platform as a significant contribution to the training
          journey of users, and we aim to recognize their achievements by
          displaying their ranks as part of our institution.
        </p>
        <p className="Privacy-hed">
          6.2 Resource Access: By accessing any resource or participating in
          training programs on our EdTech platform, users acknowledge and agree
          that their ranks and related achievements may be showcased publicly.
        </p>
        <p className="Privacy-hed">
          6.3 Definition of Competitive Exams: For the purpose of this policy,
          competitive exams refer to examinations designated as such by the
          relevant authorities, including but not limited to entrance exams,
          standardized tests, and competitive assessments.
        </p>
        <p className="Privacy-hed">
          6.4 Display Method: The rank holders will be displayed on our EdTech
          platform in a designated section or page dedicated to showcasing
          achievements. The display may include the user's name, rank, and any
          relevant information highlighting their achievement.
        </p>
        <p className="Privacy-hed">
          6.5 User Consent: By using our EdTech platform, users implicitly
          consent to their ranks and related achievements being displayed
          publicly as a significant contribution to their training journey.
          Users will be informed of this policy during the registration process
          or through other means of communication.
        </p>
        <p className="Privacy-hed">
          6.6 Opt-Out Option: We respect the privacy preferences of our users.
          If a user wishes to opt-out or requests the removal of their rank from
          public display, they can contact our platform's support team, and we
          will promptly address their request.
        </p>
        <p className="Privacy-hed">
          Compliance and Amendments
          <br />
          7.1 Compliance: We are committed to complying with all applicable laws
          and regulations regarding user privacy and data protection. We handle
          personal information in accordance with our Privacy Policy.
        </p>
        <p className="Privacy-hed">
          7.2 Amendments: We reserve the right to amend or update this policy at
          any time. Any changes made will be communicated through prominent
          notice on our EdTech platform or via email. We encourage users to
          review this policy periodically.
        </p>
        <p className="Privacy-hed">
          Contact Information
          <br />
          8.1 For any questions, concerns, or requests related to this policy or
          the display of rank holders, please contact our support team at
         support@copeducation.com
        </p>

        <p className="Privacy-hed">
          8.2 By using our EdTech platform, users signify their understanding
          and acceptance of this policy regarding the display of rank holders in
          competitive exams.
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}
