import { useEffect, useState } from "react";
import { ProgressBar } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Slider from "react-slick";


export default function Aboutbody() {
  const id1 = 25;
  const id2 = 19;
  const id3 = 28;
  const id4 = 17;
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <>
      <div className="container mt-5">
        {/* <p className="About-p">remove</p> */}
        <h3 className="About-small">Our Services</h3>
      </div>
      <div className="section upstudy-about-section section-padding-02">
        <div className="container">
          <div className="about-wrap">
            <div className="row mt-4">
              <h4 className="title ms-3">ECET</h4>

              <div className="col-lg-7 order-2 order-lg-1">
                {/* About Content Start */}
                <div className="about-content">
                  <p
                    className="text"
                    style={{ whiteSpace: "0", textAlign: "justify" }}
                  >
                    Our unparalleled ECET preparation strategy encompasses a
                    wide array of resources, designed to maximize your success.
                    Our offerings include digital ebooks, guided recorded
                    lectures, as well as doubt-solving and live sessions.
                    Additionally, we provide quizzes for rigorous monitoring,
                    culminating in the most effective and comprehensive
                    preparation strategy available.
                  </p>
                  <Link to={`/services/${id1}`}>
                    <button
                      type="button"
                      className="btn btn-secondary btn-lg"
                      onClick={handleButtonClick}
                    >
                      Explore now
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <div className="row">
                  <Slider {...settings}>
                    <div>
                     
                      <img
                        src="assets/images/2222.gif"
                        alt="gif is not there"
                      />
                    </div>
                    <div>
                     
                      <img src="assets/images/Allpages.jpg" alt="" />
                    </div>
                    <div>
                     
                       <img src="assets/images/mock test-01-01.jpg" alt="" />
                    </div>
                    <div>
                       <img src="assets/images/ask doubt-01.jpg" alt="" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <h4 className="title" style={{ padding: "36px 0 0 0" }}>
            Skill Training
          </h4>

          <div className="about-wrap">
            <div className="row mt-5 ">
              <div className="col-lg-5">
                <div className="row">
                  <Slider {...settings}>
                    <div>
                      
                       <img
                        src="assets/images/Competency Training-01.jpg"
                        className="d-block "
                        alt="..."
                      />
                    </div>
                    <div>
                       <img
                        src="assets/images/Competency Evaluation-01.jpg"
                        className="d-block "
                        alt="..."
                      />
                    </div>
                    <div>
                      <img
                        src="assets/images/Profile building.jpg"
                        className="d-block "
                        alt="..."
                      />
                    </div>
                    <div>
                     <img
                        src="assets/images/Company connect-01.jpg"
                        className="d-block "
                        alt="..."
                      />
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="col-lg-7">
                {/*<!-- About Content Start -->*/}
                <div className="about-content">
                  <p className="text" style={{textAlign: "justify"}}>
                    In the Skill Training framework we focus on three essential
                    elements: competency building, competency monitoring, and
                    profile building support. This comprehensive approach
                    ensures that we connect the finest talent with the most
                    suitable companies, resulting in optimal hiring solutions
                    for businesses.
                  </p>
                </div>
                <Link to={`/services/${id2}`}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg"
                    onClick={handleButtonClick}
                  >
                    Explore now
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="about-wrap">
            <div className="row mt-5">
              <h4 className="title ms-3">Industrial Training</h4>

              <div className="col-lg-7 order-2 order-lg-1">
                {/* About Content Start */}
                <div className="about-content">
                  <p className="text" style={{textAlign: "justify"}}>
                    This program offers a win-win situation for both young
                    talents and companies. It provides students with the
                    opportunity to apply their skill set in real-world
                    scenarios, while companies can assess the students'
                    capabilities during the training program, allowing them to
                    identify potential candidates for future inclusion in their
                    plans.
                  </p>
                  <Link to={`/services/${id3}`}>
                    <button
                      type="button"
                      className="btn btn-secondary btn-lg"
                      onClick={handleButtonClick}
                    >
                      Explore now
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <div className="row">
                  <Slider {...settings}>
                    <div>
                    <img
                        src="assets/images/Preparatory trainin-2-01.jpg"
                        alt=""
                      />
                    </div>
                    <div>
                        <img
                        src="assets/images/Industrial exposure-2-01.jpg"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src="assets/images/Writing AdvisoryY-01.jpg"
                        alt=""
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <h4 className="title" style={{ padding: "36px 0 0 0" }}>
            Backlogs
          </h4>

          <div className="about-wrap">
            <div className="row mt-5 ">
              <div className="col-lg-5">
                <div className="row mb-3">
                  <Slider {...settings}>
                    <div>
                      
                         <img src="assets/images/live.jpg" alt="" />
                    </div>
                    <div>
                     <img
                        src="assets/images/EXCEL PREPARATION-01.jpg"
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src="assets/images/IMPROVE PERFORMANCE-01.jpg"
                        alt=""
                      />
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="col-lg-7">
                {/*<!-- About Content Start -->*/}
                <div className="about-content">
                  <p className="text" style={{textAlign: "justify"}}>
                    Our backlog training stands out as the most effective method
                    for comprehending complex concepts in a user-friendly
                    format. Our top-notch faculty provides dedicated support,
                    guiding you every step of the way, making the learning
                    process easy. Additionally, our vibrant student community
                    ensures that overcoming challenging subjects becomes a
                    seamless experience.
                  </p>
                </div>
                <Link to={`/services/${id4}`}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg"
                    onClick={handleButtonClick}
                  >
                    Explore now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



{/* <div className="Description">
<h3 className="title">Description</h3>
  <div className="row">
    <div className="col-12">
    {uniqueVideos.length >= 3 ? (
      <Slider {...setting}>
        {uniqueVideos.map((video) => (
          <div key={video.id}>
            {video.video_url ? (
              <div>
                <video className="myvideo" controls>
                  <source src={video.video_url} type="video/mp4" />
                </video>
              </div>
            ) : (
              <p>Sample video not available.</p>
            )}
          </div>
        ))}
      </Slider>
    ) : (
      uniqueVideos.map((video) => (
        <div key={video.id}>
          {video.video_url ? (
            <div>
              <video className="myvideo" controls>
                <source src={video.video_url} type="video/mp4" />
              </video>
            </div>
          ) : (
            <p>Sample video not available.</p>
          )}
        </div>
      ))
    )}
    </div>
  </div>
</div> */}