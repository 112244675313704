import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Refundpolicy() {
  return (
    <>
      <Header />
      <div className="container">
        <div className="Conditions-hed mb-3">Refund Policy –</div>
        <p className="Refund-hed mb-5">
          Thank you for visiting{" "}
          <a className="fw-bold" href=" www.copeducation.com">
            {" "}
            www.copeducation.com
          </a>
        </p>
        <p className="Refund-hed mb-2">Effective Refund Policy:</p>
        Non-tangible irrevocable goods ("Digital products"):
        <p className="Refund-hed ">
          We understand the importance of consumer satisfaction and strive to
          provide the best possible products and services. However, as our
          products are non-tangible irrevocable goods ("digital products"), we
          have a strict no-refund policy once the product access confirmed.
          <br />
          Exceptions and Support:
        </p>
        Contact Information:
        <br />
       <div className="Refund-hed">
       For any questions or concerns regarding our Returns and Refunds Policy,
        please don't hesitate to reach out to us at:
        <br />
        Email:{" "}
        <a className="fw-bold" href="support@copeducation.com">
          support@copeducation.com
        </a>
        Thank you for your understanding and for being a valued customer.
        <p className="Refund-hed mb-2 mt-2">
          (Note: Always ensure to keep the contact email up-to-date and
          accessible for customers to contact you easily.)
        </p>
       </div>
      
       
      </div>
      <Footer></Footer>
    </>
  );
}
