import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getCoursesCategory,
  getCoursesDetails,
  getPopularCourses,
} from "../ApiServices/HomePageController";
import Slider from "react-slick";
import { render } from "@testing-library/react";
import { Fragment } from "react";

export default function Courses() {
  const [courses, setCourses] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState(false);
  const [services, setServices] = useState();
  let category_id = 3;
  const id1 = 11;
  // let course_id = 8;
  useEffect(() => {
    getData();
    //   getServices();
  }, []);
  var data = new FormData();
  // data.append('page', '');
  data.append("category_id ", category_id);
  const getData = () => {
    setLoadings(true);
    getPopularCourses().then((response) => {
      console.log(response.data);

      if (response && response.data.status == "OK") {
        setCourses(response.data.details);

        console.log("Data Responces");

        //  console.log(response.data.details);

        setLoadings(false);
      } else {
        setLoadings(true);
        setErrors(true);
        setLoadings(false);
      }
    });
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleButtonClick = () => {
    setLoadings(true);

    // Simulate an asynchronous action, like fetching data from API or navigation delay
    setTimeout(() => {
      setLoadings(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };

  // const carousel = new bootstrap.Carousel(document.getElementById('carouselExampleControls'), {
  //     interval: window.innerWidth <= 767 ? 0 : 1000
  // });

  if (!loadings) {
    return (
      <div className="container">
        <p className="Courses-p">What’s new</p>
        <div className="d-flex justify-content-between  mb-3 mt-3">
          <h3 className="Brand">Trending Courses</h3>
          {/* <Link to="Services">
                    <button type="button" className="Brand-btn  btn-sm">All courses</button>
                </Link> */}
          <Link to={`/services/${id1}`} onClick={handleButtonClick}>
            <button type="button" className="Brand-btn">
              All courses
            </button>
          </Link>
        </div>
        <p className="section-sub" style={{ textAlign: "justify" }}>
          Popular courses encompass a wide range of subjects, from programming
          and data science to digital marketing and personal development,
          attracting a large number of learners seeking to acquire in-demand
          skills and knowledge."
        </p>

        {/* <div className="container"> */}
        <div className="course-slider">
          <Slider {...settings}>
            {courses &&
              courses.map((course, index) => {
                return (
                  <Fragment key={course.id}>
                    <>
                      <div key={course.id}>
                        <Link
                          className="col-sm-12 col-md-6 mt-5 w-100 " // Adjust col-md-6 for tablet view
                          to={`/services/sub-services/${course.id}`}
                          onClick={handleButtonClick}
                        >
                          <div className="Out-side">
                            <div className="img-bodys">
                              <img
                                src={course.image}
                                className="card-img-top"
                                alt="..."
                              />
                            </div>
                            <div className="card-bodys course-slider">
                              <h5 className="card-title mb-0">
                                {course.name} ({course.plan})
                              </h5>
                              <small>
                                Duration:{" "}
                                {course.duration != null ? course.duration : ""}
                              </small>
                              {course.content_type === 1 && (
                                <div className="row mb-3 ">
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="blue-1" href="#">
                                      {course.no_of_pdf !== null
                                        ? course.no_of_pdf
                                        : " "}{" "}
                                      Pdf's
                                    </div>
                                  </div>
                                </div>
                              )}

                              {course.content_type === 2 && (
                                <div className="row mb-3">
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="blue-1" href="#">
                                      {course.no_of_pdf !== null
                                        ? course.no_of_pdf
                                        : " "}{" "}
                                      Pdf's
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="green-1" href="#">
                                      {course.no_of_quiz !== null
                                        ? course.no_of_quiz
                                        : " "}{" "}
                                      Quiz
                                    </div>
                                  </div>
                                </div>
                              )}

                              {course.content_type === 3 && (
                                <div className="row mb-3 ">
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="red-1" href="#">
                                      {course.no_of_videos !== null
                                        ? course.no_of_videos
                                        : " "}{" "}
                                      Video's
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="blue-1" href="#">
                                      {course.no_of_pdf !== null
                                        ? course.no_of_pdf
                                        : " "}{" "}
                                      Pdf's
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-4 p-1">
                                    <div className="green-1" href="#">
                                      {course.no_of_quiz !== null
                                        ? course.no_of_quiz
                                        : " "}{" "}
                                      Quiz
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* <div className="row">
                                  <div className="price mx-xl-4 mx-ms-0 d-flex justify-content-between">
                                    <div className="Testing me-2">
                                      ₹{course.original_price}
                                    </div>
                                    <div className="Manual me-4 mx-ms-4 mx-md-3">
                                   
                                      ₹{course.discount_price}
                                    </div>
                                    <div className="Manual-buttons  ms-xl-4 ms-md-5  ">
                                   
                                      Buy
                                    </div>
                                  </div>
                                  </div>
                                   */}
                              <div className="row">
                                <div className="col-lg-6 col-6">
                                  <div className="row align-items-center nowrap-flex">
                                    <div className="col-lg-3 col-6 Testing me-0">
                                    <s>  ₹{course.original_price}</s>
                                    </div>
                                    <div className="col-lg-9 col-6 Manual me-4 me-md-0">
                                      ₹{course.discount_price}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-6  button-right margin-mob">
                                  <Link
                                    to={`/services/sub-services/${course.id}`}
                                    onClick={handleButtonClick}
                                  >
                                    <div className="Manual-buttons ">Buy</div>
                                  </Link>
                                </div>
                              </div>

                              {/* <div className="row">
                                  <div className="price mx-xl-4 ms-sm-0 d-flex justify-content-between">
                                    <div className="Testing me-xl-0 me-sm-5 me-md-5">
                                      ₹{course.original_price}
                                    </div>
                                    <span className="Manual me-xl-4 me-sm-5 me-md-5 ms-sm-3">
                                      ₹{course.discount_price}
                                    </span>
                                    <span className="Manual-buttons ms-xl-5 ms-md-5 ms-5  "> 
                                      Buy
                                    </span>
                                  </div>
                                </div> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  </Fragment>
                );
              })}
          </Slider>
        </div>
      </div>
    );
  }
}
