import { useState } from "react";
import Header from "../Header/Header";
import Categorys from "../FullHomepage/Categorys";
import Footer from "../Footer/Footer";

export default function About() {
  const [loadings, setLoadings] = useState(true);
  const handleButtonClick = () => {
    setLoadings(true);

    // Simulate an asynchronous action, like fetching data from API or navigation delay
    setTimeout(() => {
      setLoadings(false);
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };
  return (
    <>
      <div className="about-body">
        <Header />
        <div className="container" onClick={handleButtonClick}>
          <h3 className="about-head ">
            Who <span className="about-span">we are....</span>
          </h3>
          <div className="row align-items-center flex-reverse margin-cust">
            <div className="col-lg-7">
              <p className="mt-2" style={{ textAlign: "justify", fontSize: "17px " }}>
                COP (Commons of Polytechnic) is an exclusive and
                all-encompassing platform meticulously curated for diploma
                engineering students. Our platform is thoughtfully designed to
                cater to their diverse academic and career needs, providing a
                wide range of services. Our primary goal is to serve as the
                ultimate one-stop solution, empowering diploma engineering
                students to achieve their unique aspirations with utmost
                convenience and success. At COP, we offer specialized & high
                quality content for the ECET exam, ensuring students are
                well-prepared to excel in the examination and secure admissions
                to their desired engineering colleges. Our experienced faculty
                members are dedicated to providing high-quality coaching,
                focusing on key exam topics and strategies. Along with the
                academic support we offer skill training where our focus on
                three crucial elements: competency development, ongoing
                competency assessment, and support for building professional
                profiles.
              </p>
            </div>
            <div className="col-lg-5 mt-5 ">
              <img
                src="assets/images/Group 1000004862 (1).png"
                alt=""
                className="image-overlay-1"
              />
            </div>
          </div>
          {/* <br /> */}
          <p className="" style={{ textAlign: "justify", fontSize: "17px", marginTop: "20px" }}>
            By adopting this all-encompassing approach, we ensure the seamless
            alignment of top-notch talent with the most fitting companies,
            ultimately delivering optimal hiring solutions for businesses.
            Furthermore, we provide support for students during their industrial
            training with the intention to create a mutually beneficial scenario
            for both young talents and companies. It empowers students to apply
            their skill set in real-world situations, gaining valuable
            experience. Concurrently, companies have the opportunity to assess
            the students' capabilities throughout the training program, enabling
            them to identify potential candidates for future inclusion in their
            plans. We understand that some students may face challenges in
            certain subjects, which is why we offer assistance to help them
            clear their backlog subjects. Our esteemed faculty members provide
            personalized guidance and support, ensuring students overcome their
            academic obstacles and stay on track towards their goals. At COP, we
            strive to empower diploma engineering students with the necessary
            tools, guidance, and support to achieve their academic and career
            aspirations.
          </p>
          <div className="text-center mt-5 pb-2">
            <h3 className="about-story">
              {" "}
              OUR <span className="about-span">STORY</span>
            </h3>
          </div>
          <p className="" style={{ textAlign: "justify", fontSize: "17px" }}>
            Established in 2014, COP is dedicated to providing high-quality
            education with a focus on overall grooming and development of our
            students. In the span of six years, our passion project has achieved
            remarkable success, with over 80% of enrolled students securing top
            ranks and gaining admission to the top 5 colleges in Andhra Pradesh
            and Telangana States. We are thrilled to announce the relaunch of
            COP, now in a digital format, with a team of exceptional experts
            from premier institutions. This new platform aims to cater to
            students seeking comprehensive ECET preparation, upgrading skills,
            industrial training support and assistance in clearing backlogs,.
            The launch of our Edtech platform marks a significant leap forward
            in improving teaching efficiency. It offers a range of features such
            as detailed textbooks, high-quality images, engaging animations,
            interactive quizzes, discussion platforms, and real-life examples.
            We are eagerly embarking on this journey, driven by our commitment
            to revolutionize education and providing students with an
            unparalleled learning experience.
          </p>
          <div className="about-story  text-center mt-5 pb-2">
            <h3 className="about-story mt-3 mb-2">
              {" "}
              OUR   <span className="about-span">LEGACY</span> CONTRIBUTORS
            </h3>
          </div>
        </div>
        {/* <div className="container">
          <div className="cards mb-4 ">
            <div className="row g-0">
              <div className="col-sm-4">
                <img
                  src="assets/images/image 80.png"
                  className="img-fluid-1 text-center"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="cards-body">
                  <h5 className="card-title-1">Shiva</h5>
                  <a
                    className="card-sub-1"
                    href="https://www.linkedin.com/in/sivarami-reddy-challa-b0034b54/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    linked<i className="fab fa-linkedin"></i>
                  </a>
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    Shiva holds a Bachelor's degree in Mechanical Engineering
                    from KL University and a Master's degree from IIT Bombay.
                    With 6 years of teaching experience, he specializes in
                    instructing Diploma Engineering Students (ECET) and has also
                    authored 6 textbooks dedicated to ECET preparation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container ">
          <div className="row">
            <div className="col-sm-6 bottom-margin">
              <div className=" card-1 ">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <img
                      src="assets/images/Group 1000004711 (2).png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body-1">
                      <h5 className="card-title-1">Keshav</h5>
                      <p className="card-sub-1">
                        <a
                          className="card-sub-1"
                          href="#"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          linked<i className="fab fa-linkedin"></i>
                        </a>
                      </p>
                      <p
                        className="card-text-1"
                        style={{ textAlign: "justify" }}
                      >
                        Bachelor's in pharmacy from Bapatla college of pharmacy
                        and done masters form JNTU university. More than six
                        years of Industry experience with Various MNC’s like
                        Sanofi, Pfizer and Aurobindo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6  ">
              <div className="card-1 ">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <img
                      src="assets/images/Group 1000004711 (2).png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body-1">
                      <h5 className="card-title-1">Sudha</h5>
                      <a
                        className="card-sub-1"
                        href="https://www.linkedin.com/in/sudhamayee-pochiraju-03809215a/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        linked<i className="fab fa-linkedin"></i>
                      </a>
                      <p
                        className="card-text-1"
                        style={{ textAlign: "justify !important" }}
                      >
                        Bachelor's in architecture (Silver Medalist) from SSAA.
                        Done masters from IIT Bombay. One year of Research
                        experience with the Ministry of Rural Development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className="row mt-4 mb-5">
            <div className="col-sm-6 bottom-margin">
              <div className=" card-1">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <img
                      src="assets/images/Group 1000004711 (2).png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body-1">
                      <h5 className="card-title-1">Upendra</h5>
                      <p className="card-sub-1">
                        {" "}
                        linked<i className="fab fa-linkedin"></i>
                      </p>
                      <p
                        className="card-text-1"
                        style={{ textAlign: "justify !important" }}
                      >
                        Bachelor's in mechanical engineering from Raghu
                        engineering college and done masters from NIT Agartala.
                        Two years association with COP and currently working at
                        ISRO as a Scientist/Eng-SD.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6  ">
              <div className="card-1">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <img
                      src="assets/images/Group 1000004711 (2).png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body-1">
                      <h5 className="card-title-1">Ravikanth</h5>
                      <a
                        className="card-sub-1"
                        href="https://www.linkedin.com/in/ravi-kanth-99284b16/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        linked<i className="fab fa-linkedin"></i>
                      </a>
                      <p
                        className="card-text-1"
                        style={{ textAlign: "justify !important" }}
                      >
                        A Skilled Cinematographer and Video Editor, boasting a
                        stellar 15+ year career across diverse industries
                        including the renowned Star India Private Limited. At
                        COP Education, Ravi has harnessed his vast experience to
                        serve as a top-tier production specialist, instrumental
                        in crafting top-class Edtech content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <Categorys/> */}
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row align-items-center " >
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Shiva.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Shiva(IIT Bombay)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Keshava.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Keshava(JNTUK)</h5>
                  </div>

                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Upendra.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Upendra (NIT Agarthala)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row align-items-center " >
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Lavanya.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Lavanya(IIT Kharagpur)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Manoranjan.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Manoranjan (NIT Raipur)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Vijayabala.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Vijayabala<br /> (Anna University)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row align-items-center " >
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Kalpana.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Kalpana (Anna University)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Ayush.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Ayush (BITS Mesra)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Satish.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Satish (Anna University)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-4">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-4 about-images animated">
                    <img
                      src="/assets/images/Viswajeet.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Vishwajeet (NIT Durgapur)</h5>
                  </div>
                  <div className="col-sm-4 about-images animated">
                    <img
                      src="/assets/images/Sara ali.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Sara Ali (IIIT Bangalore)</h5>
                  </div>
                  <div className="col-sm-4 about-images animated">
                    <img
                      src="/assets/images/Rajesh kannan.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Rajesh (Anna University)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row align-items-center " >
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Hemanth.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Hemanth <br />(BVC Engineering)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Ravi.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Ravi (Production Lead)</h5>
                  </div>
                  <div className="col-sm-4 about-images">
                    <img
                      src="/assets/images/Ajay.png"
                      className="img-fluid-2 text-center"
                      alt="..."
                    />
                    <h5 className="card-title-1 text-center mt-3">Ajay (Editor)</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}