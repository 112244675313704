// export const BASEURL = process.env.PUBLIC_URL;
export const BASEURL = process.env.PUBLIC_URL;

export const CATEGORY = '/courses/categories?auth_code=';
export const COURSES_CATEGORY = '/courses/courses-by-category?auth_code=';
export const SERVICES_CATEGORY ='/courses/categories?auth_code=';
export const POPULAR_COURSES ='/courses/popular-courses?auth_code=';
export const COURSES_DETAILS='/courses/courses-detail?auth_code=';
export const SUBJECT_TOPICS='/courses/subject-topics?auth_code=';
export const SEND_MAIL='/user/contact-email';
export const COURSES_PLANS='/courses/plans?auth_code=';