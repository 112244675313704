export default function Features() {
  return (
    <>
      <div className="section upstudy-features-section-02">
        <div className="container">
          <div className="features-wrap">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-6">
                <div className="inner-circle">
                  <img
                    src="assets/images/featureimages-1.png"
                    alt=""
                    className="feature-images"
                  />
                  <h3 className="text-dark">High Quality Content</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="inner-circle">
                  <img
                    src="assets/images/featureimages-2.png"
                    alt=""
                    className="feature-images"
                  />
                  <h3 className="text-dark">Learn From Top Educators</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="inner-circle">
                  <img
                    src="assets/images/featureimages-3.png"
                    alt=""
                    className="feature-images3"
                  />
                  <h3 className="text-dark">Unlimited Access</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="inner-circle">
                  <img
                    src="assets/images/featureimages-4.png"
                    alt=""
                    className="feature-images4"
                  />
                  <h3 className="text-dark">Learn Any Time, Any Where </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
