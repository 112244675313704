import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  getCoursesDetails,
  getSubjectTopics,
} from "../ApiServices/HomePageController";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loader, { ProgressBar } from "react-loader-spinner";
import Slider from "react-slick";
// import {Carousel} from '../../node_modules/bootstrap/package.json'

export default function SubServices() {
  const [details, setDetails] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [topic, setTopic] = useState([]);
  const [subjectId, setSubjectId] = useState([]);
  const [videos, setVideos] = useState([]);
  const { id } = useParams();
  let course_id = id;

  useEffect(() => {
    getData();
    getTopic(subjectId);
  }, []);
  useEffect(() => {
    if (subjects.length > 0) {
      setSubjectId(subjects[0].id);
    }
  }, []);
  const getData = () => {
    setLoadings(true);
    getCoursesDetails(course_id)
      .then((response) => {
        // console.log(response.data);
        if (response && response.data.status === "OK") {
          setDetails(response.data.details);
          setSubjects(response.data.details["subjects"]);
          setVideos(response.data.details["sample_videos"]);
          setSubjectId(response.data.details["subjects"][0].id); // Set the first subject as active by default
          getTopic(response.data.details["subjects"][0].id); // Fetch topics for the first subject

          setLoadings(false);
        } else {
          setErrors(true);
          setLoadings(false);
        }
      })
      .catch((error) => {
        setErrors(true);
        setLoadings(false);
      });
  };

  const getTopic = (subjectId) => {
    console.log(subjectId);
    setLoadings(true);
    getSubjectTopics(subjectId)
      .then((response) => {
        // console.log(response.data);
        if (response && response.data.status === "OK") {
          setTopic(response.data.details);
          setLoadings(false);
        } else {
          setTopic([]);
          setErrors(true);
          setLoadings(false);
        }
      })
      .catch((error) => {
        setErrors(true);
        setLoadings(false);
      });
  };
  const uniqueVideos = videos.reduce((acc, video) => {
    if (!acc.find((v) => v.id === video.id)) {
      acc.push(video);
    }
    return acc;
  }, []);

  const setting = {
    infinite: true,
    slidesToShow: uniqueVideos.length >= 3 ? 3 : 1, // Show three videos in one row if there are at least three videos, else show one
    slidesToScroll: 1,
    
    arrows: true,
    prevArrow: <div className="custom-slick-arrow slick-prev">Previous</div>,
    nextArrow: <div className="custom-slick-arrow slick-next">Next</div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="about-body">
        <Header />
        <div
          // src="../../assets/images/online-01.png"
          className="course"
          alt="..."
        />

        <>
          <div className="container" id="sub">
            <div className="row">
              <div className="col-sm-2">
                <div className="circle">
                  <img
                    src={details.image}
                    className="image-overlay-5 "
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-sm-12 col-xl-10 col-md-12">
                <div className="d-flex justify-content-between  sub-store">
                  <h3 className="sub-title mb-0">
                    {details.name}({details.plan})
                    <br />
                    <p className="sub-small mt-3">
                      Duration:{" "}
                      {details.duration !== null ? details.duration : ""}
                    </p>
                  </h3>
                  {/* <span className="sub-font">₹</span> */}
                  <div className="sub-1 d-flex align-items-center mx-2">
                    <h4 className="sub-fonts">₹{details.original_price}</h4>{" "}
                    <h5 className="sub-font">₹{details.discount_price}</h5>
                  </div>
                </div>

                <div className="row  mt-3">
                  {/* <div className="d-flex  mb-3  col-xl-9 col-md-9 col-sm-12 sub-store">
                    <>
                      {details.content_type === 1 && (
                        // <div className="row mb-3">
                        <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                          <div className="blue-1" href="#">
                            {details.no_of_pdf !== null
                              ? details.no_of_pdf
                              : " "}{" "}
                            Pdf's
                          </div>
                        </div>
                        // </div>
                      )}
                      {details.content_type === 2 && (
                        // <div className="row mb-3">
                        <>
                          <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                            <div className="blue" href="#">
                              {details.no_of_pdf !== null
                                ? details.no_of_pdf
                                : " "}{" "}
                              Pdf's
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-2 col-sm-12 p-1">
                            <div className="green-1" href="#">
                              {details.no_of_quiz !== null
                                ? details.no_of_quiz
                                : " "}{" "}
                              Quiz
                            </div>
                          </div>
                        </>
                        // </div>
                      )}
                      {details.content_type === 3 && (
                        // <div className="row mb-3">
                        <>
                          <div className="col-lg-2 col-md-2 col-sm-12 p-1">
                            <div className="red-1" href="#">
                              {details.no_of_videos !== null
                                ? details.no_of_videos
                                : " "}{" "}
                              Video's
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-12 p-1">
                            <div className="blue-1" href="#">
                              {details.no_of_pdf !== null
                                ? details.no_of_pdf
                                : " "}{" "}
                              Pdf's
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 p-1">
                            <div className="green-1" href="#">
                              {details.no_of_quiz !== null
                                ? details.no_of_quiz
                                : " "}{" "}
                              Quiz
                            </div>
                          </div>
                        </>
                        // </div>
                      )}
                    </>
                  </div> */}
                  <div className="d-flex mb-3 col-xl-9 col-md-9 col-sm-12 sub-store">
                    <>
                      {details.content_type === 1 && (
                        <div className="col-lg-3 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                          <div className="blue-1" href="#">
                            {details.no_of_pdf !== null
                              ? details.no_of_pdf
                              : " "}{" "}
                            Pdf's
                          </div>
                        </div>
                      )}
                      {details.content_type === 2 && (
                        <>
                          <div className="col-lg-3 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                            <div className="blue" href="#">
                              {details.no_of_pdf !== null
                                ? details.no_of_pdf
                                : " "}{" "}
                              Pdf's
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                            <div className="green-1" href="#">
                              {details.no_of_quiz !== null
                                ? details.no_of_quiz
                                : " "}{" "}
                              Quiz
                            </div>
                          </div>
                        </>
                      )}
                      {details.content_type === 3 && (
                        <>
                          <div className="col-lg-2 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                            <div className="red-1" href="#">
                              {details.no_of_videos !== null
                                ? details.no_of_videos
                                : " "}{" "}
                              Video's
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                            <div className="blue-1" href="#">
                              {details.no_of_pdf !== null
                                ? details.no_of_pdf
                                : " "}{" "}
                              Pdf's
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-12 p-1"> {/* Added col-12 for mobile */}
                            <div className="green-1" href="#">
                              {details.no_of_quiz !== null
                                ? details.no_of_quiz
                                : " "}{" "}
                              Quiz
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </div>


                  <div className="col-sm-2 col-xl-2 text-end">
                    <button className="small-buttons me-1 me-md-0">
                      Buy now
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>

                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mb-5">
              <div className="col-sm-12 col-md-12 col-xl-12">
                <div className="Description">
                  <h3 className="title">Description</h3>
                  <p
                    className="about-titel mt-2 "
                    style={{ textAlign: "justify" }}
                  >
                    {details.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="Descriptions">
              <h3 className="title">Sample video</h3>
              <div className="row">
                <div className="col-12 mb-2">
                  {uniqueVideos.length >= 3 ? (
                    <Slider {...setting}>
                      {uniqueVideos.map((video) => (
                        <div key={video.id}>
                          {video.video_url ? (
                            <div>
                              <video className="myvideo" controls>
                                <source
                                  src={video.video_url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : (
                            <p>Sample video not available.</p>
                          )}
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    uniqueVideos.map((video) => (
                      <div key={video.id}>
                        {video.video_url ? (
                          <div>
                            <video className="myvideo" controls>
                              <source src={video.video_url} type="video/mp4" />
                            </video>
                          </div>
                        ) : (
                          <p>Sample video not available.</p>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            {
              <div className="wrapper">
                <div className="tab-container-wrapper">
                  <ul className="tab-container">
                    <li className="button-row mb-5">
                      <div className="button-wrapper">
                        {/* {subjects.map((subject, index) => (
                       <button
                       key={subject.id}
                       className={`btn ${subjectId === subject.id ? 'active' : ''}`}
                       onClick={() => {
                         setSubjectId(subject.id);
                         getTopic(subject.id);
                       }}
                     >
                       {subject.name !== null ? subject.name : ''}
                     </button>
                    ))} */}
                        {subjects.map((subject, index) => (
                          <button
                            key={index}
                            className={`btn ${
                              subject && subject.id === subjectId
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              if (subject && subject.id) {
                                setSubjectId(subject.id);
                                getTopic(subject.id);
                              } else {
                                console.error(
                                  "Error: Cannot read properties of null (reading 'id')"
                                );
                              }
                            }}
                            style={{ fontSize: "13px" }}
                          >
                            {subject && subject.name ? subject.name : ""}
                          </button>
                        ))}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            }
            <div className="row">
              {loadings ? (
                <div className="text-center">
                  <ProgressBar
                    type="Oval"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  />
                </div>
              ) : !topic || topic.length === 0 ? (
                <div className="text-center">
                  <img
                    src="/assets/images/images.png"
                    alt="..."
                    style={{ width: "400px", height: "400px" }}
                  />
                </div>
              ) : (
                topic.map((topics, index) => (
                  <div className="col-sm-6 col-md-3 col-xl-3 " key={index}>
                    <div className="cours-boxs">
                      <img
                        src={topics.image !== null ? topics.image : ""}
                        className="img-fluidss mb-2"
                        alt="..."
                        width={"50%"}
                      />
                      <p className="cours-smals">{topics.name}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
        {/* )} */}

        <Footer />
      </div>
    </>
  );
}

{
  /* <div className="Description">
<h3 className="title">Description</h3>
  <div className="row">
    <div className="col-12">
    {uniqueVideos.length >= 3 ? (
      <Slider {...setting}>
        {uniqueVideos.map((video) => (
          <div key={video.id}>
            {video.video_url ? (
              <div>
                <video className="myvideo" controls>
                  <source src={video.video_url} type="video/mp4" />
                </video>
              </div>
            ) : (
              <p>Sample video not available.</p>
            )}
          </div>
        ))}
      </Slider>
    ) : (
      uniqueVideos.map((video) => (
        <div key={video.id}>
          {video.video_url ? (
            <div>
              <video className="myvideo" controls>
                <source src={video.video_url} type="video/mp4" />
              </video>
            </div>
          ) : (
            <p>Sample video not available.</p>
          )}
        </div>
      ))
    )}
    </div>
  </div>
</div> */
}
