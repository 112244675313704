import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Blog() {
  return (
    <>
    <Header></Header>
    <div className='container mt-5'>
    <h1 className='pt-5'>coming soon</h1>
    </div>
    {/* <Footer></Footer> */}
    </>
  
  )
}
