import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

export default function Conditions() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="Conditions-hed mb-3">Terms & Conditions –</div>
        <p className="Conditions-small">
          These Terms & Conditions ("Terms") govern the usage of our website
           ("<b><a href="https://copeducation.com/">https://copeducation.com</a></b>"), our applications ("<b><a href="https://play.google.com/store/apps/details?id=com.cop_education">COP Education</a></b>"), or any
          products or services provided in connection with the
          Application/Website/products (<b><a href="https://copeducation.com/services/1">Services</a></b>), as well as any methods of
          registration or product usage, including through SD cards, tablets, or
          other storage/transmitting devices. These Terms establish a legal
          agreement between COPT Education Private Limited ("Company/We/Us/Our")
          and its users ("User/You/Your").
        </p>
        <p className="Conditions-small">
          By using the Application, Website, Services, or products, including
          registration with or without payment/subscription, you acknowledge and
          accept these Terms and agree to be bound by them. It is essential to
          carefully read both the Terms and the Company's privacy policy
          (<b><a href="https://copeducation.com/Privacy">Privacy Policy</a></b>) regarding registration, Application usage, Website,
          Services, and products before proceeding. In case of any inconsistency
          between the Terms and other policies concerning the Application,
          Website, Services, or products, the provisions of these Terms shall
          prevail.
        </p>
        <p className="Conditions-small">
          If you do not agree to abide by these Terms or the Privacy Policy,
          kindly refrain from using the Application, Website, or accessing the
          Services or products. Moreover, any access to our Services,
          Application, or products through registration or subscription is
          non-transferable.
        </p>
        <p className="Conditions-small">
          1) Except as mentioned below, all information, content, material,
          trademarks, services marks, trade names, and trade secrets including
          but not limited to the software, text, images, graphics, video, script
          and audio, contained in the Application, Website, Services and
          products are proprietary property of the Company (“Proprietary
          Information”). No Proprietary Information may be copied, downloaded,
          reproduced, modified, republished, uploaded, posted, transmitted or
          distributed in any way without obtaining prior written permission from
          the Company and nothing on this Application or Website or Services
          shall be or products deemed to confer a license of or any other right,
          interest or title to or in any of the intellectual property rights
          belonging to the Company, to the User. You may own the medium on which
          the information, content or materials resides, but the Company shall
          at all times retain full and complete title to the information,
          content or materials and all intellectual property rights inserted by
          the Company on such medium. Certain contents on the Website may belong
          to third parties. Such contents have been reproduced after taking
          prior consent from said party and all rights relating to such content
          will remain with such third party. Further, you recognize and
          acknowledge that the ownership of all trademarks, copyright, logos,
          service marks and other intellectual property owned by any third party
          shall continue to vest with such party and You are not permitted to
          use the same without the consent of the respective third party.
        </p>
        <p className="Conditions-small">
          2) Your usage of our products, Website, Application, and Services is
          strictly intended for personal and non-commercial purposes. Any
          utilization of the Application, Website, Services, or products beyond
          personal use is strictly prohibited. Your personal and non-commercial
          use of this Application, Website, products, and/or our Services must
          adhere to the following restrictions:
        </p>
        <p className="Conditions-small">
          (i) You are not allowed to decompile, reverse engineer, or disassemble
          the contents of the Application and/or our Website and/or
          Services/products. Additionally, you must not modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell any information or
          software obtained from the Application and/or our Website and/or
          Services/products. Furthermore, you must not remove any copyright,
          trademark registration, or other proprietary notices from the contents
          of the Application and/or our Website and/or Services/products.
        </p>
        <p className="Conditions-small">
          (ii) You shall not use this Application and/or our Website and/or any
          of our products or Services for any commercial purposes. Advertising
          or selling the Application, products, Services, or domain names,
          whether for profit or not, is strictly prohibited. Soliciting others,
          including soliciting contributions or donations, or using any public
          forum for commercial purposes, is also not allowed.
        </p>
        <p className="Conditions-small">
          (iii) You are prohibited from using the Application, Website, our
          products, and Services in any manner that is unlawful or harms the
          Company or any other person or entity, as determined by the Company.
        </p>
        In addition to the above, users are not permitted to engage in the
        following prohibited activities while availing our Services:
        <br />
        <li className="Conditions-small">
          Providing access to any content that is misleading, unlawful, harmful,
          threatening, abusive, tortious, defamatory, libelous, vulgar, obscene,
          child-pornographic, lewd, lascivious, profane, invasive of another's
          privacy, hateful, or racially, ethnically, or otherwise objectionable
          is strictly prohibited.
        </li>
        <li className="Conditions-small">
          Additionally, engaging in stalking, intimidation, harassment, or
          inciting others to commit violence is not allowed. Transmitting
          material that encourages criminal offenses, civil liability, or
          breaches of relevant laws, regulations, or codes of practice is also
          forbidden.
        </li>
        <li className="Conditions-small">
          Users must not interfere with others' use or enjoyment of the
          Application/Website/Services or engage in any act that infringes on
          intellectual property rights, copyrights, or proprietary rights of
          others. Making available content or materials without proper
          authorization under law or contractual agreements is not permissible
          unless you own or control the rights to such content.
        </li>
        <li className="Conditions-small">
          Impersonating any individual or entity, misrepresenting affiliation,
          or posting false information is not tolerated. It is strictly
          forbidden to post or transmit materials containing viruses, trojan
          horses, worms, spyware, time bombs, or other harmful computer
          programming routines that could damage or limit the functionality of
          the Application/services or compromise the interests and rights of
          other users.
        </li>
        <li className="Conditions-small">
          Accessing or using the Application/Website/Services/products in a way
          that disrupts, damages, disables, or overburdens the servers or
          networks is prohibited. Violating applicable laws, requirements,
          procedures, policies, or regulations related to the access or use of
          the Application/Website/Services/products is strictly forbidden
        </li>
        <li className="Conditions-small">
          Attempting to disrupt the security of the
          Application/Website/Services/products, gain unauthorized access to
          user accounts, passwords, servers, or networks, or interfere with
          other users' access to the services is not allowed.
        </li>
        <li className="Conditions-small">
          The Services should be used only for purposes allowed by these Terms
          and any applicable laws or guidelines in the relevant jurisdiction.
          Violation of any specified terms in the Terms for the use of the
          Application/Website/Services/products is considered a breach of the
          rules and is strictly prohibited.
        </li>
        <p className="Conditions-small">
          3) By using or submitting content on the Services (referred to as
          "Material"), you grant us a worldwide, non-exclusive, royalty-free
          license (with the right to sublicense) to use, copy, reproduce,
          process, adapt, modify, publish, transmit, display, and distribute
          your Material in any and all media or distribution methods, whether
          currently known or developed in the future. We may associate your
          Material with you, except as described below. You acknowledge and
          agree that other users of the Services may use your Material in the
          same way as any other content available through the Services. They may
          also fork, tweak, and repurpose your Material in accordance with these
          Terms. Please note that if you delete your user account, your Material
          and name may still remain available through the Services.
        </p>
        <p className="Conditions-small">
          4) While every effort has been made to offer the most current,
          correct, and clearly expressed information in the preparation of the
          Application/Website/Services/products and their contents, inadvertent
          errors may occur. The Company disclaims any responsibility for such
          errors and the accuracy of the information that may be contained in
          the Application. We welcome feedback from users to improve the
          Application and its contents, making them error-free and
          user-friendly. The Company reserves the right and discretion to make
          any changes, corrections, or withdraw/add contents at any time without
          prior notice.
        </p>
        <p className="Conditions-small">
          5) Neither the Company nor any third parties provide any warranty or
          guarantee as to the accuracy, timeliness, performance, completeness,
          or suitability of the information and materials found or offered on
          the Application/Website/Services/products for any particular purpose.
          You acknowledge that such information and materials may contain
          inaccuracies or errors, and we expressly exclude liability for any
          such inaccuracies or errors to the fullest extent permitted by law.
        </p>
        <p className="Conditions-small">
          6) Our Website provides Users with access to compiled educational
          information and related sources on an "As Is" basis, and we assume no
          liability for the accuracy, completeness, or non-obsolescence of such
          information. We shall not be responsible for updating or ensuring
          continuity of the information contained on the Website. Additionally,
          we would not be liable for any errors that might appear in such
          information, which is compiled from third-party sources, or for any
          unavailability of such information. From time to time, the Website may
          include links to other websites for further information. Please note
          that these links are provided for your convenience and do not signify
          that we endorse the website(s). We have no responsibility for the
          content of the linked website(s). Without the Company's prior written
          consent, you may not create a link to the Website from another website
          or document.
        </p>
        <p className="Conditions-small">
          7) The contents of the Application/Services/products are developed
          based on the concepts covered in the structured curriculum syllabus
          prescribed for students of various courses. The usage of the
          Application/Services/products is not intended as a substitution for
          the curriculum-based education provided by educational institutions
          but is meant to supplement the same by explaining and presenting the
          concepts in an easily understandable manner. The basic definitions and
          formulae of the subject matter remain the same. The Company
          acknowledges that there are various means of delivering structured
          curriculum pedagogy, and the inclusion of methods in the
          Application/Services/products does not imply endorsement of any
          particular method, nor does exclusion imply disapproval. Please note
          that subscribing to the Application or using our
          Services/Website/products does not guarantee admission to any
          educational institutions, passing of any exams, or achievement of any
          specified percentage of marks in any examinations.
        </p>
        <p className="Conditions-small">
          8) Certain contents in the Application/Services/Website/products,
          particularly those relating to assistance in preparations for
          administrative services, may contain opinions and views. The Company
          shall not be responsible for such opinions or any claims resulting
          from them. Furthermore, the Company makes no warranties or
          representations whatsoever regarding the quality, content,
          completeness, or adequacy of such information and data.
        </p>
        <p className="Conditions-small">
          9) Some portions of the Services are interactive, and we encourage
          Users to contribute content, which may or may not be subject to
          editorial control before posting. The Company assumes no
          responsibility or liability for any material communicated by third
          parties through the Services. We reserve the right, at our sole
          discretion, to remove, review, edit, or delete any content.
        </p>
        <p className="Conditions-small">
          10) Likewise, we will not be held responsible or liable for any
          content uploaded directly by Users on the Website, regardless of
          whether we have certified any uploaded answers. We cannot verify
          whether such questions, answers, or contents placed by any User
          contain infringing materials or not.
        </p>
        <p className="Conditions-small">
          11) The Company, including its subsidiaries and affiliates, may
          contact Users through SMS, email, or phone based on any form of access
          to the Application, Services, Website, or registrations through any
          source. This contact may be for providing information about our
          offerings, products, important updates, or seeking permission for
          product demonstrations. By registering yourself, you agree to allow us
          to contact you through various means and hold the{" "}
        </p>
        <p className="Conditions-small">
          12) Company indemnified against any liabilities arising from
          contacting a mobile number registered with the Do Not Call (DNC)
          database.
        </p>
        <p className="Conditions-small">
          13) Upon registration, the Company may contact you through the
          provided mobile number, email, or other contact information to
          effectively provide the Services. You expressly permit the Company to
          contact you and the student utilizing the Services through the
          aforementioned means at any time after registration. Additionally, the
          Company may monitor the download and usage of the
          Application/Services/products and their contents by the User/student
          to analyze and discuss usage for improved effectiveness.
        </p>
        <p className="Conditions-small">
          14) The User expressly permits the Company to address student doubts,
          provide study plans, offer progress updates, feedback, and mentoring
          through telephone or email, with express consent from the legal
          guardian/parent of the User or through any other agreed-upon forum.
        </p>
        <p className="Conditions-small">
          15) While the Company endeavors to train sales and service personnel
          related to its products for quality control, it makes no warranties or
          representations concerning their quality and competence. Any feedback
          from Users regarding such personnel is welcomed, and the Company
          reserves the right to take appropriate action based on the feedback.
        </p>
        <p className="Conditions-small">
          16) Access to specific features of the Services, such as doubt
          clearance and mentoring services, may be subject to separate terms,
          conditions, and fair usage policies. The Company reserves the right to
          determine the criteria for providing various elements of the Services
          to different categories of Users, based on its policies. Therefore,
          subscribing to the Application/products or registering does not
          automatically entitle the User to all elements of Services provided by
          the Company. The Company retains the discretion to grant access to
          certain elements of Services and decide their continuity.
        </p>
        <p className="Conditions-small">
          17) We reserve the right to extend, cancel, discontinue, withdraw
          prematurely, or modify any of Our Services at Our discretion.
        </p>
        <p className="Conditions-small">
          18) The Company's products and Services, including the Application and
          content, are compatible only with certain designated
          devices/tablets/instruments/hardware. The Company is not obligated to
          provide functioning products and/or services for any instruments not
          recognized by the Company or purchased from third parties that are
          incompatible with the Company's offerings. The Company also reserves
          the right to update the list of compatible devices as needed.
        </p>
        <p className="Conditions-small">
          19) The Company shall not be held responsible for any loss or damage
          caused to tablets or any other hardware and/or software, including
          data loss or changes in processing speed, resulting from the use of
          our products and Services.
        </p>
        <p className="Conditions-small">
          20) To encourage Customer Retention and Loyalty, COP allows its
          Customers, Fintech Partners, and other stakeholders to refer customers
          to purchase COP’S Products & Services. The Company may, at its
          discretion, pay referral bonuses subject to applicable laws. However,
          it should be noted that this referral bonus policy is optional and
          selective, and the decision of the Company is final and not open to
          dispute or challenge. Customers, Fintech Partners, or other
          stakeholders should not conduct business with the Company in
          anticipation of receiving such bonuses, as the Company's payment of
          bonuses is entirely dependent on internal parameters, demographics,
          and economic factors.
        </p>
        <p className="Conditions-small">
          21) During the purchase, you must specify the delivery address. All
          products will be delivered directly to the specified address at the
          time of ordering, and it is not possible to change the address after
          the order is processed. If there is any change in the address, you
          must inform us in writing well in advance of the shipping date.
          Failure to provide consistent and accurate name or address information
          may result in non-delivery of the product(s).
        </p>
        <p className="Conditions-small">
          22) (a) If a product is damaged at the time of delivery, the Company
          will bear the shipping charges for its return. However, for returning
          a product for any other reasons, it is the User's responsibility to
          arrange for the return, and shipping charges will be borne by the
          User. (b) We urge you not to accept any product package that appears
          to be tampered with, opened, or damaged upon delivery. Products must
          be returned in the same condition as delivered by the Company. Any
          products showing signs of use or damage will not be accepted for
          return. (c) All requests for product returns must be made within 15
          (fifteen) days from the date of delivery. Please note that no refunds
          will be processed or entertained after this 15-day period.
        </p>
        <p className="Conditions-small">
          23) You acknowledge that the Company is not the manufacturer of the
          instrument/medium/hardware, and any defects related to it should be
          reported to the manufacturer, whose details will be specified on the
          packaging. The Company shall not be responsible for any such defects
          or issues related to the instrument/medium/hardware.
        </p>
        <p className="Conditions-small">
          24) To access the Services and utilize the Application/products, you
          must register with the Application/Services/products and maintain an
          account with them. During registration, you'll need to provide certain
          information, such as your name, mobile number, e-mail address,
          residential address, grade/class of the student, school name, and
          payment information (credit/debit card details if required), along
          with any other information considered necessary by the Application.
        </p>
        <p className="Conditions-small">
          Regarding the information you provide, it is your sole responsibility
          to ensure its accuracy, completeness, and that it is up-to-date. You
          are also accountable for maintaining the confidentiality of your
          account information and for any activities that occur under your
          account. Remember to log out of your account at the end of each
          session, and if you suspect any unauthorized use, promptly notify the
          Company. In case of a potential security breach or misuse of your
          account, the Company may ask you to change your password or
          temporarily suspend your account as deemed appropriate in the
          circumstances. The Company will not be liable for any loss or damage
          resulting from your failure to comply with this requirement.
        </p>
        <p className="Conditions-small">
          It's important to note that your ability to use your account may
          depend on external factors like internet service providers and network
          availability, and the Company cannot guarantee continuous
          accessibility to the Application. Apart from the disclaimers mentioned
          in the Terms, the Company will not be held liable for any damages
          arising from your inability to log into your account and access the
          Application's services at any time.
        </p>
        <p className="Conditions-small">
          Individuals who possess the legal capacity to contract under the
          Indian Contract Act, 1872, are eligible to register for the
          Application and access our products or services. However, minors,
          undischarged insolvents, and individuals deemed ineligible under the
          Act are not allowed to register for or use our products or services.
          If you are a minor and wish to use our products or services, you must
          do so under the supervision and consent of your legal guardian or
          parents who have agreed to these Terms. By utilizing the
          Application/Website/Services, it is assumed that a minor has obtained
          the necessary consent from their legal guardian or parents.
        </p>
        <p className="Conditions-small">
          The Company holds no responsibility for any consequences arising from
          the misuse of our Application, products, or services by any person,
          including minors, who have registered for our offerings. By using our
          products or services, you warrant that all data provided by you is
          accurate and complete. In the case of minors, you confirm that the
          student using the Application has obtained the consent of their
          parent/legal guardian.
        </p>
        <p className="Conditions-small">
          The Company reserves the right to terminate the subscription and deny
          access to products or services if it is discovered that a user is
          under 18 years of age and lacks the proper consent from their
          parent/legal guardian or if any provided information is found to be
          inaccurate. Ensuring that you meet the necessary eligibility criteria
          is your sole responsibility. Minors (under the age of 18) should seek
          consent from their parents/legal guardians before providing any
          personal information about themselves or their family members on the
          Application.
        </p>
        <p className="Conditions-small">
          Indemnification: By using the Application/Website/Services, you agree
          to defend, indemnify, and hold the Company, its officers, directors,
          employees, and agents harmless from any and all claims, damages,
          obligations, losses, liabilities, costs, or debts, including
          attorney's fees, arising from:
        </p>
        <p className="Conditions-small">
          (i) Your use of and access to the Application/Website/Services,
        </p>
        <p className="Conditions-small">
          (ii) Your violation of any term in these Terms or any other policy of
          the Company,
        </p>
        <p className="Conditions-small">
          (iii) Your infringement upon any third party rights, including
          copyright, property, or privacy rights, and
        </p>
        <p className="Conditions-small">
          (iv) Any claim that Your use of the Application/Website/Services
          caused damage to a third party.
        </p>
        <p className="Conditions-small">
          This defense and indemnification obligation will survive the
          termination of these Terms.
        </p>
        <p className="Conditions-small">
          Limitation of Liability: Under no circumstances shall the Company, its
          officers, directors, employees, partners, or agents be liable to you
          or any third party for any special, incidental, indirect,
          consequential, or punitive damages, including those resulting from
          loss of use, data, or profits, or any other claims arising out of or
          in connection with your use of, or access to, the Application.
        </p>
        <p className="Conditions-small">
          Breach of Terms: If you breach these Terms, you acknowledge that the
          Company may suffer irreparable harm for which monetary compensation
          may not be sufficient. In such cases, the Company is entitled to seek
          an injunction from any court of competent jurisdiction to prevent
          further breaches. The Company's right to seek such relief does not
          limit its ability to pursue other remedies available by law.
        </p>
        <p className="Conditions-small">
          Modifications and Discontinuation: The Company reserves the right to
          change, modify, suspend, or discontinue any aspect(s), features, or
          functionality of the Application or the Services at any time without
          notice. Additionally, the Company may amend these Terms from time to
          time without prior notice. The Company makes no commitments, express
          or implied, to maintain or continue any aspect of the Application. You
          agree that the Company shall not be liable to you or any third party
          for any modifications, suspension, or discontinuance of the
          Application/Services. All prices are subject to change without notice.
        </p>
        <p className="Conditions-small">
          Disclaimer: The use of this Website, the Application, and the Services
          is entirely at your own risk. The Company provides them on an "as is"
          basis with all faults and without any warranty of any kind. The
          Company disclaims all warranties and conditions regarding the Website,
          Application, products, and Services, including but not limited to, all
          implied warranties and conditions of merchantability, fitness for a
          particular purpose, title, accuracy, timeliness, performance,
          completeness, suitability, and non-infringement. The Company shall not
          be liable for any damages arising out of or in connection with the use
          or performance of this Site, the Application, or the Services. It is
          your responsibility to ensure that the Services provided by us meet
          your specific requirements.
        </p>
        <p className="Conditions-small">
          Governing Law and Jurisdiction: The Terms shall be governed by and
          construed in accordance with the laws of India, without regard to
          conflict of law principles. Further, the Terms shall be subject to the
          exclusive jurisdiction of the competent courts located in Bangalore,
          and you hereby accede to and accept the jurisdiction of such courts.
        </p>
        <p className="Conditions-small">
          Notice: The Company will deliver all notices to You via email to your
          account or through general notifications on the Application. If You
          need to provide any notice to the Company, please use the email
          address support@copeducation.com.
          <br />
          <br />
          Entire Agreement: The Terms, along with the Privacy Policy, and any
          other applicable guidelines for the Application, constitute the
          complete agreement between You and the Company regarding your access
          to and use of the Application, Website, and its Services.
          <br />
          Assignment: You are not permitted to assign or transfer Your
          obligations or rights under these Terms to any third party. However,
          the Company reserves the right to freely transfer its rights under the
          Terms to third parties without seeking Your consent.
          <br />
          Severability: In the event that a court of competent jurisdiction
          deems any provision of these Terms unenforceable, the court shall
          enforce that provision to the maximum extent permitted to reflect the
          intent of the parties, and the remainder of the Terms will remain in
          full force and effect.
          <br />
          Waiver: Any failure by the Company to enforce or exercise any
          provision or right under these Terms does not constitute a waiver of
          that provision or right.
          <br />
          Relationship: You acknowledge that Your participation on the
          Application does not establish an employment, agency, partnership,
          joint venture, or franchise relationship with the Company.
          <br />
          The Company provides these Terms to inform You about the conditions
          governing your use of the Website/Application and Services. You
          acknowledge that the Company has given You a reasonable opportunity to
          review these Terms, and You have agreed to abide by them.
        </p>
        <p className="Conditions-small">
          Feedback: Any feedback You provide regarding the Application will be
          considered non-confidential, and the Company may use such information
          without restrictions. By submitting feedback, You represent and
          warrant that: (i) Your feedback does not contain confidential or
          proprietary information of You or any third parties; (ii) the Company
          has no obligation of confidentiality concerning the feedback; (iii)
          the Application may already be considering or developing something
          similar to the feedback; and (iv) You are not entitled to any
          compensation or reimbursement from the Company for the feedback unless
          specified otherwise.
        </p>
        <p className="Conditions-small">
          Under no circumstances shall the Company be held responsible for any
          content provided by other users, even if the content is offensive or
          hurtful. Please exercise caution while accessing the Application.
        </p>
        <p className="Conditions-small">
          Customer Care: We are committed to providing You with the best
          possible experience. If, by any chance, You encounter any issues,
          please don't hesitate to contact us at support@copeducation.com.
        </p>
      </div>

      <Footer />
    </>
  );
}
