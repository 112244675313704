import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import { Link, useParams } from "react-router-dom";
import { getCoursesCategory, getServicesCategory } from "../ApiServices/HomePageController";
import { useState, useEffect } from "react";
import { ProgressBar } from "react-loader-spinner"; 
import Slider from "react-slick";

export default function Services() {
  const [courses, setCourses] = useState([]);
  const [loadings, setLoading] = useState(false);
  const [setErrors] = useState(false);
  const [services, setServices] = useState([]);
  const params = useParams();
  const [activePage, setActivePage] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  let category_id = params.id;

  useEffect(() => {
    setActivePage(category_id);
    fetchData(category_id); // Fetch data whenever category_id changes
  }, [category_id]);

  const fetchData = async (categoryId) => {
    setLoading(true); // Show loader while fetching
    try {
      await getServices();
      await getCourseByCategory(categoryId);
    } catch (error) {
      setErrors(true);
    }
    setLoading(false); // Hide loader after content is fetched
  };

  const getServices = async () => {
    setServices([]); // Clear previous services
    try {
      const response = await getServicesCategory();
      if (response && response.data.status === "OK") {
        setServices(response.data.details);
      } else {
        setErrors(true);
      }
    } catch (error) {
      setErrors(true);
    }
  };

  const getCourseByCategory = async (categoryId) => {
    setCourses([]); // Clear previous courses
    try {
      const response = await getCoursesCategory(categoryId);
      if (response && response.data.status === "OK") {
        setCourses(response.data.details);
      } else {
        setErrors(true);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      setErrors(true);
    }
  };

  const numSlides = courses.length;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: numSlides === 3 ? numSlides : 3,
    slidesToScroll: numSlides === 3 ? numSlides : 3,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: numSlides <= 3 ? numSlides : 3,
          slidesToScroll: numSlides <= 3 ? numSlides : 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: numSlides <= 1 ? numSlides : 1,
          slidesToScroll: numSlides <= 1 ? numSlides : 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: numSlides <= 1 ? numSlides : 1,
          slidesToScroll: numSlides <= 1 ? numSlides : 1,
        },
      },
    ],
  };

  const handleSetActivePage = (page) => {
    setActivePage(page);
    setLoading(true); // Show loader when changing services
    getCourseByCategory(page).finally(() => setLoading(false)); // Fetch courses for the new service
  };

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 10); // Scroll to the top of the page
    }, 0);
  };

  return (
    <>
      <div className="about-body">
        <Header />

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="svc" alt="..." />
        </div>

        <>
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-6 text-start">
                <div className="Services-widget">
                  <h4 className="Services" onClick={handleToggleMenu}>
                    Services
                    {isMobileView && (
                      <span
                        id="myt"
                        className={`dropdown-toggle ${isMenuOpen ? "open" : ""}`}
                      ></span>
                    )}
                  </h4>
                  <div className="widget-links">
                    <li className="links">
                      <span className="menu-expand "></span>
                      <span className="mobile-menu-expand"></span>
                      <ul className={`sub-menu  ${isMenuOpen ? "open" : ""}`}>
                        {services.map((service, index) => (
                          <li key={index}>
                            <Link
                              to={`/services/${service.id}`}
                              onClick={() => handleSetActivePage(service.id)}
                            >
                              <div
                        className={`menu menu-item-has-childrens ${
                          
                                  service.id == activePage ? "active-menu" : ""
                                }`}
                              >
                                {service.title}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-9 new-center">
                <div className="row align-items-center">
                  {loadings ? (
                    <div className="text-center" style={{ width: "100%" }}>
                      {/* Loader Animation */}
                      <ProgressBar
                        type="Oval"
                        color="#00BFFF"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : courses.length > 0 ? (
                    Object.values(
                      courses.reduce((acc, course) => {
                        if (!acc[course.plan]) {
                          acc[course.plan] = [];
                        }
                        acc[course.plan].push(course);
                        return acc;
                      }, {})
                    ).map((courseGroup, index) => (
                      <div className="row pe-0" key={index}>
                        <p className="plane mb-2">{courseGroup[0].plan}</p>
                        {courseGroup.length > 3 ? (
                          <Slider {...settings}>
                            {courseGroup.map((course) => (
                              <div key={course.id} className={`col-md-${index % 2 === 0 ? "6" : "6"}`}>
                                <Link to={`/services/sub-services/${course.id}`} onClick={handleButtonClick}>
                                  <div className="Out-sides">
                                    <div className="img-body">
                                      <img
                                        src={course.image !== null ? course.image : ""}
                                        className="card-img-top"
                                        alt="..."
                                      />
                                    </div>
                                    <div className="card-bodys">
                                      <h5 className="card-title mb-0">{course.name}</h5>
                                      <small>Duration: {course.duration !== null ? course.duration : ""}</small>
                                      {/* Content Type Display */}
                                      {course.content_type === 1 && (
                                        <div className="row justify-content-between mb-3">
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                          </div>
                                        </div>
                                      )}
                                      {course.content_type === 2 && (
                                        <div className="row justify-content-between mb-3">
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                          </div>
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="green-1">{course.no_of_quiz !== null ? course.no_of_quiz : ""} Quiz</div>
                                          </div>
                                        </div>
                                      )}
                                      {course.content_type === 3 && (
                                        <div className="row justify-content-between mb-3">
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="red-1">{course.no_of_videos !== null ? course.no_of_videos : ""} Video's</div>
                                          </div>
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                          </div>
                                          <div className="col-lg-4 col-xs-12 p-1">
                                            <div className="green-1">{course.no_of_quiz !== null ? course.no_of_quiz : ""} Quiz</div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="row">
                                        <div className="price mx-2 d-flex justify-content-between">
                                          <div className="Testing">₹{course.original_price}</div>
                                          <span className="Manual">₹{course.discount_price}</span>
                                          <span className="Manual-buttons">Buy</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          courseGroup.slice(0, 2).map((course) => (
                            <div key={course.id} className="col-md-6 col-xl-4">
                              <Link to={`/services/sub-services/${course.id}`} onClick={handleButtonClick} className="w-100">
                                <div className="Out-sides">
                                  <div className="img-body">
                                    <img
                                      src={course.image !== null ? course.image : ""}
                                      className="card-img-top"
                                      alt="..."
                                    />
                                  </div>
                                  <div className="card-bodys">
                                    <h5 className="card-title mb-0">{course.name}</h5>
                                    <small>Duration: {course.duration !== null ? course.duration : ""}</small>
                                    {course.content_type === 1 && (
                                      <div className="row justify-content-between mb-3">
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                        </div>
                                      </div>
                                    )}
                                    {course.content_type === 2 && (
                                      <div className="row justify-content-between mb-3">
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                        </div>
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="green-1">{course.no_of_quiz !== null ? course.no_of_quiz : ""} Quiz</div>
                                        </div>
                                      </div>
                                    )}
                                    {course.content_type === 3 && (
                                      <div className="row justify-content-between mb-3">
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="red-1">{course.no_of_videos !== null ? course.no_of_videos : ""} Video's</div>
                                        </div>
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="blue-1">{course.no_of_pdf !== null ? course.no_of_pdf : ""} Pdf's</div>
                                        </div>
                                        <div className="col-lg-4 col-xs-12 p-1">
                                          <div className="green-1">{course.no_of_quiz !== null ? course.no_of_quiz : ""} Quiz</div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="row">
                                      <div className="col-lg-7 col-6">
                                        <div className="row align-items-center">
                                          <div className="col-lg-4 col-6 Testing me-0">
                                            <s> ₹{course.original_price}</s>
                                          </div>
                                          <div className="col-lg-8 col-6 Manual me-4 me-md-0 ml-3">
                                            ₹{course.discount_price}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6 button-right margin-mob">
                                        <Link to={`/services/sub-services/${course.id}`} onClick={handleButtonClick}>
                                          <div className="Manual-buttons">Buy</div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="text-center w-100"></div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      </div>
    </>
  );
}
