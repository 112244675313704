import React from "react";

export default function CarouselScreen() {
  return (
    <>
      {/* <div>
      <h1>Carousel Slider Example</h1>
      <CarouselSlider />
    </div> */}
      <section>
        <div className="container">
          <div className="testimonial-wrap-02">
            <div className="section-title text-start mt-5 mb-3">
              <div className="Brand mt-5">Testimonial</div>
              <p
                className="section-sub mt-3 mb-0"
                style={{ textAlign: "justify" }}
              >
                A testimonial is a statement or endorsement provided by a
                satisfied customer, highlighting their positive experience with
                a product, service, or company.{" "}
              </p>
            </div>
          </div>
        </div>
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="false"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="container">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="cards-wrapper">
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-1-card-1.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            GIt is a turning point to my life!! it is a place
                            where I found meaning of Education. after entering
                            into COP, I just felt good about education how easy
                            it is, by a simplified technique. thank you, Shiva
                            sir you are the best person, best guide, best friend
                            i never met before.
                            <div className="hed-subm text-end  ">
                              By Sandeep Jesus
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-1-card-2.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titels"
                            style={{ textAlign: "justify" }}
                          >
                            Best coaching center organised by shiva sir I have
                            never seen this kind of institution. Especially
                            shiva sir's lectures are excellent and motivated us
                            to ecxel in academics and gain knowledge. Difficult
                            concepts can be understood very easily.I had been a
                            part of COP for only 10 days and my rank is 120.
                            Definetly COP is very useful for building your
                            future. Thank you soo much sir,if we wouldn't find
                            you we would have faced a lot of difficulties. Now
                            life is going good because of all your efforts. I
                            sincerely suggest everyone to join in COP.
                            <div className="hed-sub text-end mt-2">
                              By Vishnu Prasad
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-1-card-3.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            Perfect institute for ECET, sir's will motivate us
                            and they are highly educated and all subjects are
                            explained in detailed till the students catch the
                            subject perfectly, comparing other institutions this
                            is excellent and this is for only limited students
                            so we can feel the comfort and concentrate on
                            studying. I love to study in this Institute.
                            <div className="hed-subm text-end  ">
                              By Srinath Sangama
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="cards-wrapper">
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-2-card-1.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            I can say undoubtedly this is the best coaching
                            institute in Hyderabad not only for ECET and also
                            for engineering. This institute teaches real meaning
                            of engineering. I will give rating 4.99/5 that 0.1
                            because no one is perfect remains something to learn
                            i hope this 0.1 fulfill in this academic year.
                            <div className="hed-sub text-end mt-4">
                              By Varisi Dhanunjay
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-2-card-2.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            Well teaching nd find good experience over der..I
                            just went for 10 days coaching nd secured 150 rank.
                            Special care taken on every student. Thanking to
                            Shiva Sir for such wonderful experience
                            <div className="hed-subs text-end  ">
                              By Manikanta Ganasari
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-2-card-3.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            Grt place to get a good knowledge . Faculty gives
                            full info abt each n every topic in detail even a
                            AVG student can catch it . Time usage is very
                            perfect. Day by day schedule is perfectly proffered
                            finally want to say that to gain a good knowledge
                            prefer this .and I thank our faculty member for
                            giving me a grt knowledge
                            <div className="hed-sub text-end mt-4">
                              By Rahul Reddy
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="cards-wrapper">
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/bootstrap-multiple-items-carousel-slide-3-card-1.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            I feel glad to be a part of such a good institution
                            which take care of each and every individual and
                            helps them in brightening their future by teaching
                            concept. If u join 'COP' and attend the classNamees
                            regularly i m sure u goanna rock in not only ECET
                            but every competitive examination.
                            <div className="hed-sub text-end mt-4">
                              By Naveen CH
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-3-card-2.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titels"
                            style={{ textAlign: "justify" }}
                          >
                            It is a best coaching center for diploma students to
                            achieve good rank in ECET. Everyone wants to join in
                            perfect coaching centers. I am the lucky person as I
                            am the one of the students of COP. This COP is held
                            by SHIVA SIR helped mee allot for my studies and
                            motivative classes by sir are awesome it gives
                            strength to us to achieve our goals. Subject classes
                            will be useful for all exams in life. Good faculty,
                            NC classes with Imp. Notes.
                            <div className="hed-sub text-end mt-4">
                              By Suma Latha
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="image-wrapper">
                        {/* <img src="https://codingyaar.com/wp-content/uploads/multiple-items-carousel-slide-3-card-3.jpg" alt="..."/> */}
                      </div>
                      <div className="card-body">
                        <div className="hed-text">
                          <div
                            className="hed-titel"
                            style={{ textAlign: "justify" }}
                          >
                            It is a great feeling.. And great time with cop.
                            Shiva sir ur amazing. After listening ur classNamees
                            and inspiring words I felt good about education.
                            Finally, I salute to your patience.Thank For
                            Everything.....
                            <div className="hed-subm text-end ">
                              By Hari Krishna
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
